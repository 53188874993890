import IndexedDbHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';

import type * as IndexModule from 'o365.pwa.declaration.shared.dexie.objectStores.Index.d.ts';

export class Index implements IndexModule.Index {
    static objectStoreDexieSchema: string = "&[appId+databaseId+objectStoreId+id], [appId+databaseId+objectStoreId], [appId+databaseId], appId, databaseId, objectStoreId, id";

    public id!: string;
    public appId!: string;
    public databaseId!: string;
    public objectStoreId!: string;
    public keyPath!: string | Array<string> | null;
    public isPrimaryKey!: boolean;
    public isUnique!: boolean;
    public isMultiEntry!: boolean;
    public isAutoIncrement!: boolean;

    public get schema(): string {
        let indexSchema = '';

        if (this.isAutoIncrement) {
            indexSchema += '++';
        }

        if (this.isUnique) {
            indexSchema += '&';
        }

        if (this.isMultiEntry) {
            indexSchema += '*';
        }

        if (Array.isArray(this.keyPath)) {
            indexSchema += `[${this.keyPath.sort().join('+')}]`;
        } else if (typeof this.keyPath === 'string') {
            indexSchema += this.keyPath;
        }

        return indexSchema;
    }

    constructor(
        id: string,
        appId: string,
        databaseId: string,
        objectStoreId: string,
        keyPath: string | Array<string> | null,
        isPrimaryKey: boolean = false,
        isUnique: boolean = false,
        isMultiEntry: boolean = false,
        isAutoIncrement: boolean = false
    ) {
        if (isPrimaryKey === false && keyPath === null) {
            throw new Error('Only primary key can have its keypath(s) ommitted');
        }

        if (Array.isArray(keyPath)) {
            keyPath.sort();
        }

        this.id = id;
        this.appId = appId;
        this.databaseId = databaseId;
        this.objectStoreId = objectStoreId;
        this.keyPath = keyPath;
        this.isPrimaryKey = isPrimaryKey;
        this.isUnique = isUnique;
        this.isMultiEntry = isMultiEntry;
        this.isAutoIncrement = isAutoIncrement;
    }

    public async save(): Promise<void> {
        await IndexedDbHandler.updateIndex(this);
    }

    public async delete(): Promise<void> {
        await IndexedDbHandler.deleteIndex(this);
    }

    public async forceReload(): Promise<Index | null> {
        return await IndexedDbHandler.getIndexFromIndexedDB(this.appId, this.databaseId, this.objectStoreId, this.id);
    }
}

export default Index;
